import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
    Box, Button, CircularProgress, Container, FormControl,
    Grid, Icon, InputLabel, MenuItem, Paper, Select,
} from '@mui/material';
import { Input } from '../form';
import { DisciplineService, Head } from '../../shared';
import { MenuDrawer } from '../Dashboard';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorResponse, SuccessResponse } from '../Modals';
import { useAuthContext } from '../../shared/hooks';

const schema = yup.object().shape({
    name: yup.string().required('Campo obrigatorio'),
    description: yup.string().required('Campo obrigatorio'),
    status: yup.number().required('Campo obrigatorio'),
    sort_order: yup.number().required('Campo obrigatorio'),
});

export function AddAndUpdateDisciplina() {
    const params = useParams()
    const [isUpdate, setIsUpdate] = useState(false);

  const { token } = useAuthContext();
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            name: '',
            description: '',
            status: 0,
            sort_order: undefined,
        }
    });

    const [Loading, setLoading] = useState(false);
    const [MsgSuccess, setMsgSuccess] = useState(undefined);
    const [MsgError, setMsgError] = useState(undefined);
    const [CourseUpdate, setCourseUpdate] = useState(null);

    useEffect(() => {
        if (params && params.id && typeof Number(params.id) === 'number') {
            setIsUpdate(true);
            DisciplineService.getOne(params.id)
            .then((data) => setCourseUpdate(data.data))
            .catch((error) => console.error(error ))
        }
    }, [params, isUpdate])

    useEffect(() => {
        if (CourseUpdate) {
            setValue('name', CourseUpdate.name);
            setValue('description', CourseUpdate.description);
            setValue('sort_order', CourseUpdate.sort_order);
            setValue('status', CourseUpdate.status);
        }
    }, [CourseUpdate]);

    const cleanData = () => {
        setValue('name', '');
        setValue('description', '');
        setValue('sort_order', '');
        setValue('status', '');
    }

    const submitData = async (data) => {
        try {
            setLoading(true);
            const response = await DisciplineService.POST_subjects(data, token);
            cleanData();
            console.log(response);
            setMsgSuccess('Disciplina cadastrado com sucesso.');
        } catch (error) {
            console.log(error);
            setMsgError(error.error);
        } finally {
            setLoading(false);
        }
    }

    const UpdateData = async (data) => {
        setLoading(true)
        try {
            const response = await DisciplineService.updateDiscipline(params.id, data);
            console.log(response);
            cleanData();
            setMsgSuccess('Disciplina actualizado com sucesso.');
        } catch (error) {
            console.log(error);
            setMsgError(error.error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <MenuDrawer page='Disciplina'>
            <Head title={isUpdate ? 'Actualizar dados do Disciplina' : 'Cadastra Disciplina'} />
            {!!MsgSuccess && <SuccessResponse title={MsgSuccess} setClose={setMsgSuccess} urlNext='/academic/discipline' />}
            {!!MsgError && <ErrorResponse title={MsgError} setClose={setMsgError} />}
            <Box component={Paper} m={2} borderRadius={2} pb={2}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        {!isUpdate
                            ? (
                                <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Cadastro</h1>
                                    <p>Preencha todos os campos para efectuar o cadastro</p>
                                </div>
                            )
                            : (
                                <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Actualizar</h1>
                                    <p>Edite todos os dados desejado para que a actualização seja bem sucedida</p>
                                </div>
                            )
                        }
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={!!isUpdate ? handleSubmit(UpdateData) : handleSubmit(submitData)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} >
                                    <InputLabel className='label'>Nome da disciplina</InputLabel>
                                    <Controller
                                        control={control}
                                        name='name'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Digite o nome do funcionário"}
                                                color='primary'
                                                error={!!errors.name}
                                                helperText={errors.name ? errors.name.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <Controller
                                        control={control}
                                        name='status'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (

                                            <>
                                                <InputLabel className='label'>Estado</InputLabel>
                                                <FormControl fullWidth
                                                    margin="normal">

                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.status}
                                                        label='Selecione'
                                                    >
                                                        <MenuItem key={1} value={1 ?? ''}>Activo</MenuItem>
                                                        <MenuItem key={0} value={0 ?? ''}>Inativo</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <InputLabel className='label'>Ordem</InputLabel>
                                    <Controller
                                        control={control}
                                        name='sort_order'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                type={'number'}
                                                placeholder={'Digite a ordem de apresentação do Disciplina'}
                                                error={!!errors.sort_order}
                                                helperText={errors.sort_order ? errors.sort_order.message : ''}
                                                color='primary' />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} >
                                    <InputLabel className='label'>Descrição</InputLabel>
                                    <Controller
                                        control={control}
                                        name='description'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                multiline
                                                rows={4}
                                                margin="normal"
                                                onChange={onChange}
                                                value={value ?? ''}
                                                placeholder={'Digite a descrição'}
                                                error={!!errors.description}
                                                helperText={errors.description ? errors.description.message : ''}
                                                color='primary' />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} mb={2} >
                                    {isUpdate ?
                                        <>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                fullWidth
                                                type='submit'
                                                disabled={Loading}
                                                endIcon={Loading ? <CircularProgress size={'1rem'} /> : <Icon>save</Icon>}
                                                onSubmit={handleSubmit(UpdateData)}
                                            >
                                                Actualizar
                                            </Button>
                                        </>
                                        :
                                        <Button
                                            variant='contained'
                                            color='success'
                                            fullWidth
                                            type='submit'
                                            disabled={Loading}
                                            onSubmit={handleSubmit(submitData)}
                                            endIcon={Loading ? <CircularProgress size={'1rem'} /> : <Icon>save</Icon>}
                                            sx={{ py: 1.9 }}
                                        >
                                            Guardar
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}