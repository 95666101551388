import styles from './Banner.module.css';
import React from 'react';
import ImageStudent from '../assets/images/aluno.png';

const Banner = ({ setModal }) => {
    return (
        <section className={styles.banner}>
            <div className={styles.blocoTexto}>
                <h1 className='title'>
                    INFQE: Transforme sua escola em um ambiente inteligente e eficiente.
                </h1>
                <p>
                    Cansado da burocracia e da falta de organização na sua escola?
                    Com o INQE, você centraliza toda as informações em um só lugar,
                    otimiza processos e libera tempo para o que realmente importa: o ensino.
                </p>

                <div className={styles.blockBtn}>
                    <button onClick={() => setModal(true)}>Escreva-se</button>
                    <button>Consulta</button>
                </div>
            </div>
            <div className={styles.blocoImagem}>
                <img src={ImageStudent} className={styles.image} alt='imagem da instituição provedora' />
            </div>
        </section>
    )
}

export default Banner
