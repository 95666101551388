import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfPreview = ({ pdfFile }) => {
    const [numPages, setNumPages] = useState(null);
    console.log(pdfFile);
    
    // Plugin para a interface padrão
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                    </Worker> */}
                <div style={{ height: '100%', width: '100%' }}>
                    {/* <Viewer
                        fileUrl={pdfFile}
                        // plugins={[defaultLayoutPluginInstance]}
                        
                    /> */}
                    <iframe
                        src={pdfFile}
                        allowFullScreen
                        width="100%"
                        // height="600px"
                        // title="PDF Viewer"
                    >

                    </iframe>
                </div>
        </div>
    );
};

export default PdfPreview;
