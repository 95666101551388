import { Box, Button, CircularProgress, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import Input from '../../../components/formComponents/Input'
import { toast } from 'react-toastify'
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';


// const schema = yup.object({
//   nif: yup.string().matches(/(^[\d]{9}\w{2}\d{3}$)|(^\w[\w \d]\d{6}\d?$)|(^\w[\w \d]\d[\w\d]\d{5}$)/, 'Número do documento incorreto.')
// });


export const SendNIf = ({ setClose, nif, onChangeNif, loading = false }) => {
  const { zIndex } = useTheme()
  const { handleSubmit, control, formState: {errors}} = useForm({defaultValues: {
    nif: ''
  }});

  const [searchParams, setSearchParams] = useSearchParams();

    const doc = useMemo(() => {
        return searchParams?.get('doc')
    }, [])


  const Submit = () => {
    if(nif && nif.length >= 8) {
      setClose()
    }else {
      toast.error('Precisa informar corretamente o Número de seu documento')
    }
  }

  return (
    <Box
      top={0}
      left={0}
      position={'absolute'}
      zIndex={zIndex.drawer + 15}
      width={'100vw'}
      height={'100vh'}
      sx={{ background: 'rgba(0,0, 0, .5)' }}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      padding={1}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
        px={2}
        width={480}
        height={300}
        minHeight={300}
        bgcolor={'white'}
        borderRadius={2}
        boxShadow={2}
        overflow={'hidden'}
        component={'form'}
        noValidate
        onSubmit={handleSubmit(Submit)}
      >
        <Box mt={4} display='flex' justifyContent='start'>
          <h1 className='title'>Informe nº do documento</h1>
        </Box>
        <Box width={'100%'} mt={1} mx={2}>
          <Controller
            control={control}
            name='nif'
            rules={{pattern: {value:
              doc === '1' ? /(^[\d]{9}\w{2}\d{3}$)/ 
              : /(^\w[\w \d]\d{6}\d?$)|(^\w[\w \d]\d[\w\d]\d{5}$)/,
              message: doc === '1' ? 'Número do documento incorreto.\n Precisa fornecer número de bilhete valido!'
              : 'Número do documento incorreto.\n Precisa fornecer número de passaporte valido!'
            }
            }}
            render={({ field: {value, onChange} }) =>(
             <div> 
                <Input label='B.I / Passaporte'
                  placeholder={`Digite o seu número de ${doc === '1' ? 'bilhete de identidade' :'passaporte'}`}
                  
                  value={value ?? ''}
                  onChange={({ target }) => {onChange(target.value);onChangeNif(target.value)}}
                />
                {!!errors.nif?.message && <small className='error'>{errors.nif?.message}</small>}
              </div>
            )}
          />
        </Box>
        <Box sx={{ width: '100%' }} >
          <Button
            variant='contained'
            type='submit'
            style={{ padding: '.6rem 1rem', background: '#FFBB11 !important', color: '#222' }}
          >
            {loading ?
              <CircularProgress size={'1rem'} color='success' /> : <>Continuar</>
            }
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
