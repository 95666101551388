import { Box, Button, useTheme } from '@mui/material'
import React from 'react'

import imgSuccess from '../../assets/check-clipart-gif-animation-18.gif';
import { Link } from 'react-router-dom';
import { PnoWrap } from '../paragraphy';

export function SuccessResponse({setClose, urlNext='', title = 'Cadastro feito com sucesso.'}) {
    const { zIndex } = useTheme()
  return (
    <Box top={0} left={0}
     position={'absolute'} 
     zIndex={zIndex.drawer + 15} 
     width={'100vw'} height={'100vh'}
     sx={{background: 'rgba(55,55, 20, .1)'} }
     display={'flex'}
     alignItems={'center'}
     justifyContent={'center'}
    >
      <Box height={350} width={600} bgcolor={'white'} borderRadius={6} boxShadow={2} overflow={'hidden'}>
        <Box display={'flex'} justifyContent={'center'}>
            <img src={imgSuccess} width={350} alt='Image success'/>
        </Box>
        <Box display={'flex'} gap={2} justifyContent={'center'} mb={2}>
            <PnoWrap text={title} fontSize={18} fontWeight={'bold'} color='#77B43F'></PnoWrap>
        </Box>
        <Box display={'flex'} gap={2} justifyContent={'center'}>
            <Box>
                <Button color='error' onClick={() => setClose(undefined)}>Fechar</Button>
            </Box>
            <Link to={urlNext} >
                <Button color='success'>Continuar</Button>
            </Link>
        </Box>
      </Box>
    </Box>
  )
}
