
import './App.css';
import { RoutesApp } from './routes/index.routes';

function App() {
  return (
    <section className="App">
      <RoutesApp />
    </section>
  );
}

export default App;
