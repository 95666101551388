// styles
import styles from './EscolaRegister.module.css';
// dependencies
import React, { useEffect, useState } from 'react';
// components
import { Head, inscription_services, schoolService } from '../../shared';
import Header from '../../components/Header';
import Input from '../../components/formComponents/Input';
import Button from '../../components/formComponents/Button';
import Select from '../../components/formComponents/Select';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';

const schema = yup.object().shape({
    name: yup.string().required('Campo Obrigatório'),
    school_type_id: yup.number().required('Campo Obrigatório'),
    level_education_id: yup.number().optional(),
    email: yup.string().required('Campo Obrigatório'),
    province_id: yup.number().required('Campo Obrigatório').moreThan(0),
    municipality_id: yup.number().required('Campo Obrigatório'),
    decree_creation: yup.string().notRequired(),
    reference_point: yup.string().optional(),
    school_number: yup.number().optional(),
    location_id: yup.number().optional().moreThan(0),
    public_school_id: yup.number().optional(),
    logo: yup.mixed().optional(),
    name_director: yup.string().required('Campo Obrigatório'),
    document: yup.string().required('Campo Obrigatório').matches(/(^[\d]{9}\w{2}\d{3}$)/, 'formato do BI invalido'),
    birthdate: yup.string().required('Campo Obrigatório'),
    gender: yup.string().required('Campo Obrigatório'),
})


const EscolaRegister = () => {
    const [form, setForm] = React.useState(true);
    const navigate = useNavigate();
    /* const [countries, setCountries] = useState([]) */
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            name: '',
            logo: undefined,
            school_type_id: undefined,
            level_education_id: undefined,
            province_id: undefined,
            municipality_id: undefined,
            reference_point: '',
            email: '',
            decree_creation: null,
            school_number: undefined,
            location_id: undefined,
            public_school_id: undefined,
            name_director: '',
            document: '',
            birthdate: '',
            gender: '',
        }
    })

    const [level, setLevel] = useState([]);
    const [schoolType, setSchoolType] = useState([]);
    const [municipe, setMunicipe] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [schoolPublic, setSchoolPublic] = React.useState([]);
    const [province, setProvince] = useState([]);

    const getSchoolType = async () => {
        try {
            const response = await schoolService.listSchoolType();
            setSchoolType(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const getSchoolMunicipe = async () => {
        try {
            const response = await schoolService.listSchoolMunicipe();
            setMunicipe(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const getSchoolLevel = async () => {
        try {
            const response = await schoolService.listSchoolLevel();
            setLevel(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const findAllSchools = async () => {
        try {
            const { url, options } = schoolService.GET_school(undefined, 100000000, 'publico')
            const response = await fetch(url, options);
            const result = await response.json();
            setSchoolPublic(result.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getSchoolType();
        getSchoolMunicipe();
        getSchoolLevel();
        findAllSchools();
    }, []);

    const submitData = async (data) => {
        setLoading(true)
        delete data.country_id;
        const { name,
            logo,
            school_type_id,
            level_education_id,
            province_id,
            municipality_id,
            reference_point,
            email,
            decree_creation,
            school_number,
            location_id,
            public_school_id,
            name_director,
            document,
            birthdate,
            gender, } = data;
        try {
            const response = await schoolService.POST_school({ name,
                logo,
                school_type_id,
                level_education_id,
                province_id,
                municipality_id,
                reference_point,
                email,
                decree_creation,
                school_number,
                location_id,
                public_school_id });
                
            if (response.error) {
                return toast.error(response.error.message || 'Problemas ao cadastrar Escola');
            }
            if (response instanceof Error) {
                return toast.error(response.message || 'Problemas ao cadastrar Escola');
            }
            

            const addResponse = await schoolService.POST_school_director({name: name_director,
                document,
                birthdate,
                gender: gender == 1 ? 'M':'F',
                id_school: response.data?.id})

            if (addResponse instanceof Error) {
                return toast.error(addResponse.message || 'Problemas ao cadastrar o director');
            }

            toast.success('Escola cadastrada com sucesso');
            reset()
            navigate('/')
        } catch (error) {
            return toast.success('Problemas ao cadastrar a Escola');
        } finally {
            setLoading(false)
        }
    }
/* 
    const getCountries = async () => {
        try {
            const { data } = await inscription_services.getAllCountry();
            setCountries(data);
        } catch (error) {
            console.log(error);
        }
    } */
    
    const handleSelectCountry = async (id) => {
        try {
            const { data } = await inscription_services.getProvinceForCountry(6);
            setProvince(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleSelectCountry()
        /* getCountries() */
        // getUsers();
    }, [])

    const generoOptions = [
        { id: 1, name: 'Masculino' },
        { id: 2, name: 'Femenino' },
    ];

    return (
        <React.Fragment>
            <Head title='Registar Escola' />
            <Header />
            <main className={styles.register}>
                <h1 className='title'>Cadastrar Escola</h1>
                    <form noValidate onSubmit={handleSubmit(submitData)}>
                        {form ? (
                            <section>
                                <p className={styles.subtitle}>Preencha todos os dados da escola</p>
                                <div className={`${styles.blockInput}`}>
                                    <Controller
                                        control={control}
                                        name='name'
                                        render={({field: {value, onChange}}) => (
                                            <Input 
                                                errorMessage={errors.name?.message}
                                                label='Nome da Escola' 
                                                value={value} 
                                                onChange={onChange} 
                                                id='name' name='name' 
                                                placeholder='Digite o nome da escola'
                                            />
                                           
                                            )}
                                        /> 
                                    <Controller
                                        control={control}
                                        name='logo'
                                        render={({field: {value, onChange}}) => (
                                            <Input 
                                                errorMessage={errors.logo?.message} label='Logotipo' value={value} onChange={onChange} type='file' id='logo' name='logo' placeholder='Digite o nome da província' />
                                        )}
                                    /> 
                                    </div>
                                <div className={styles.blockInput}> 
                                    <Controller
                                        control={control}
                                        name='school_type_id'
                                        render={({field: {value, onChange}}) => (
                                            <Select errorMessage={errors.school_type_id?.message} label='Tipo de Escola' value={value} onChange={onChange} id='type' name='type' placeholder='Selecione o tipo de escola'
                                                values={schoolType}
                                            />
                                        )}
                                    /> 
                                    <Controller
                                        control={control}
                                        name='province_id'
                                        render={({field: {value, onChange}}) => (
                                            <Select errorMessage={errors.province_id?.message} label='Província' id='provincia'  value={value} onChange={onChange}  name='provincia' values={province} placeholder='Selecione a provínciaa'/>
                                       )}
                                    /> 
                                </div>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='municipality_id'
                                        render={({field: {value, onChange}}) => (
                                        <Select errorMessage={errors.municipality_id?.message} values={municipe} label='Município' id='municipio' value={value} onChange={onChange} name='municipio' placeholder='Selecione o nome do município' /> 
                                        )}
                                    /> 
                                    <Controller
                                        control={control}
                                        name='level_education_id'
                                        render={({field: {value, onChange}}) => (
                                        <Select errorMessage={errors.level_education_id?.message} label='Nível de Ensino' id='nivel' value={value} onChange={onChange} name='nivel' placeholder='Selecione o nível de ensino'
                                            values={level}
                                        /> 
                                        )}
                                    /> 
                                </div>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='decree_creation'
                                        render={({field: {value, onChange}}) => (
                                            <Input 
                                                errorMessage={errors.decree_creation?.message}label='Decreto de Criação' id='decreto' value={value} onChange={onChange}  name='decreto' placeholder='Digite o decreto de criação' />
                                    
                                        )}
                                    /> 
                                    <Controller
                                        control={control}
                                        name='school_number'
                                        render={({field: {value, onChange}}) => (
                                        <Input 
                                            errorMessage={errors.school_number?.message}label='Número da Escola' id='numero' value={value} onChange={onChange}  name='numero' placeholder='Digite o número da escola' />
                                        
                                        )}
                                    /> 
                                </div>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='reference_point'
                                        render={({field: {value, onChange}}) => (
                                        <Input 
                                            errorMessage={errors.reference_point?.message}label='Ponto de Referência' value={value} onChange={onChange} id='referencia' name='referencia' placeholder='Digite o ponto de referência' />
                                    
                                        )}
                                    /> 
                                    <Controller
                                        control={control}
                                        name='email'
                                        render={({field: {value, onChange}}) => (
                                        <Input 
                                            errorMessage={errors.email?.message}label='Email da Escola' value={value} onChange={onChange} type='email' id='email' name='email' placeholder='Digite o email da escola' />
                                
                                        )}
                                    /> 
                                </div>
                                <Button onClick={() => setForm(false)}>Seguinte</Button>
                            </section>
                        ) :
                            (
                                <section>
                                    <p className={styles.subtitle}>Preencha todos os dados do director</p>
                                    <Controller
                                        control={control}
                                        name='name_director'
                                        render={({field: {value, onChange}}) => (
                                                <Input 
                                                    errorMessage={errors.name_director?.message}
                                                    label='Nome do Director' 
                                                    value={value} 
                                                    onChange={onChange} 
                                                    id='director' 
                                                    name='director' 
                                                    placeholder='Digite o nome do director' 
                                                />
                                        )}
                                    />
                                    <div className={styles.blockInput}>
                                          <Input 
                                            label='Imagem' 
                                            type='file' 
                                            id='img_director' 
                                            name='img_director' 
                                            placeholder='Digite o nome do director' />
                                             
                                        <Controller
                                        control={control}
                                        name='document'
                                        render={({field: {value, onChange}}) => (
                                                <Input 
                                                    errorMessage={errors.document?.message}
                                                    label='Número do BI' 
                                                    id='bi' 
                                                    name='bi'
                                                    value={value} 
                                                    onChange={onChange}  
                                                    placeholder='Digite o núme
                                                    ro do bilhete de identidade' 
                                                />
                                                
                                        )}
                                    />
                                    </div>
                                    <div className={styles.blockInput}>
                                        <Controller
                                        control={control}
                                        name='birthdate'
                                        render={({field: {value, onChange}}) => (
                                                <Input 
                                                    errorMessage={errors.birthdate?.message}
                                                    label='Data de 
                                                    Nascimento'
                                                    value={value} 
                                                    onChange={onChange}  
                                                    type='date' id='na
                                                    scimento' name='nasc
                                                    imento' placeholder='Digite o nome do director' />
                                                    
                                                
                                        )}
                                    /><Controller
                                    control={control}
                                    name='gender'
                                    render={({field: {value, onChange}}) => (
                                        <Select errorMessage={errors.gender?.message} label='Gênero' id='genero'
                                            value={value} 
                                            onChange={onChange} 
                                            name='genero' 
                                            placeholder='Selecione o gênero'
                                            values={generoOptions}
                                        />        
                                    )}
                                />
                                    </div>
                                    <div className={styles.blockBtn}>
                                        <Button onClick={() => setForm(true)}>Voltar</Button>
                                        <Button type='submit' disabled={Loading}>{Loading ? 'Registrando...':'Registar'}</Button>
                                    </div>
                                </section>
                            )
                        }
                    </form>
            </main>
        </React.Fragment>
    )
}

export default EscolaRegister
