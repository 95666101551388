// styles
import styles from './Access.module.css';
// dependencies
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../shared/hooks";
// components
import { Input, MenuDrawer } from "../../components";
import { Head } from "../../shared";
import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Switch, Typography, useTheme } from "@mui/material";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export function Access() {
  const [data, setData] = useState([]);
  const [Profiles, setProfiles] = useState([]);
  const [RulesSelecteced, setRulesSelecteced] = useState(null);

  const [Permissions, setPermissions] = useState([]);
  const [profileSelecteced, setProfileSelecteced] = useState('');
  const { palette } = useTheme()
  const [profile, setProfile] = useState(null);

  const { addRules, getOneRules, getPermissions, updatePermissionsRules, getRules, getMenus, isLoading, token } = useAuthContext()

  const handleGetRules = async () => {

    const result = await getRules()

    if (result instanceof Error) {
      console.log(result);
    }

    setProfiles(result.data ?? [])
  }

  const handleGetOneRules = async (id) => {
    const result = await getOneRules(id)
    if (result instanceof Error) {
      console.log(result);
    }
    setRulesSelecteced(result?.data?.menus ?? [])
    console.log({ RulesSelecteced });
  }

  const handleGetPermissions = async () => {
    const result = await getPermissions()
    if (result instanceof Error) {
      console.log(result);
    }
    setPermissions(result?.data ?? [])
    console.log({ permissions: result });
  }

  const handleGetMenus = async () => {
    const result = await getMenus(token)
    if (result instanceof Error) {
      console.log(result);
    }
    setData(result?.data ?? [])
    console.log({ menu: result });
  }

  useEffect(() => {
    handleGetRules();
    handleGetMenus();
    handleGetPermissions();
  }, []);

  const handleSaveProfile = async () => {
    if (!profile) return toast.error('O campo é obrigatório!')
    const result = await addRules({ name: profile, permissions: [] })
    if (result instanceof Error) {
      toast.error('Problemas ao salvar o perfil');
    }
    toast.success('Perfil salvo com sucesso!');
  }

  const handleChangeRules = async (rules_id, menu_id, permissions_id) => {
    if (!profileSelecteced) return toast.error('Selecione o perfil');
    const profile = Profiles[Profiles.findIndex(el => el.id === rules_id)];
    const name = profile.name;
    let permissions = []
    profile?.menus.forEach(mp => {
      if (mp.menu_id === menu_id) {
        console.log({ mp });
        if (mp.permissions.length === 0 || mp.permissions.findIndex(el => el.id === permissions_id) === -1) {
          permissions.push(permissions_id)
        }
      }
    });

    const cleanData = {
      id: rules_id,
      name,
      menus: [{ menu_id, permissions }]
    }

    try {
      await updatePermissionsRules(rules_id, cleanData);
      handleGetOneRules(rules_id)
      toast.success('Perfil actualizado com sucesso!')
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <MenuDrawer page='Controle de acesso'>
      <Head title='Perfil de acesso' />
      <Box component={Paper} p={2} m={2} flex={1}>
        <Box>
          <Box display={'flex'} gap={1} mb={2} mx={1}>
            <Box width={'100%'}>
              <section className='bloco-pesquisa-botao'>
                <div style={{marginRight: '1rem'}}>
                  <FormControl sx={{ minWidth: 250, maxWidth: 250 }}>
                    <InputLabel id="demo-simple-select-label" sx={{ display: 'flex', gap: 1 }}>Perfil</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" style={{ height: '3.5rem', marginRight: '1rem' }}
                      onChange={e => { setProfileSelecteced(e.target.value); handleGetOneRules(e.target.value); }}
                      value={profileSelecteced ?? ''} label="Perfil" sx={{ py: .5 }} fullWidth
                    >
                      {Profiles.length > 0 && Profiles.map((item, id) => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.formGroup}>
                  <Input placeholder='Ex: Admin' value={profile ?? ''} onChange={(e) => setProfile(e.target.value)} />
                </div>
                <button className={`${styles.btnAdd} btn btn-add`} onClick={() => handleSaveProfile()} disabled={isLoading}>
                  Adicionar Perfil
                </button>
              </section>
            </Box>
          </Box>
        </Box>

        <section className={styles.gridSystem}>
          {data.length > 0 &&
            data.map((item) => (
              <Box key={item.id} className={styles.card}>
                <Box bgcolor={palette.primary.main} className={styles.cardHeader}>
                  <Typography align="center">{item.icon.replace('_TM', '')}</Typography>
                </Box>
                <Box px={2}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <p>Listar</p>
                    <Switch {...label}
                      onChange={() => handleChangeRules(profileSelecteced, item.id, Permissions[Permissions.findIndex((p, id) => p.name === 'list')].id)}
                      checked={
                        RulesSelecteced
                        && RulesSelecteced[RulesSelecteced.findIndex((rule, id) => rule.menu_id === item.id)]?.permissions.includes(Permissions[Permissions.findIndex((p, id) => p.name === 'list')].id)}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <p>Visualizar</p>
                    <Switch {...label}
                      onChange={() => handleChangeRules(profileSelecteced, item.id, Permissions[Permissions.findIndex((p, id) => p.name === 'view')].id)}
                      checked={RulesSelecteced
                        && RulesSelecteced[RulesSelecteced.findIndex((rule, id) => rule.menu_id === item.id)]?.permissions.includes(Permissions[Permissions.findIndex((p, id) => p.name === 'view')].id)}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <p>Criar</p>
                    <Switch {...label}
                      onChange={() => handleChangeRules(profileSelecteced, item.id, Permissions[Permissions.findIndex((p, id) => p.name === 'create')].id)}
                      checked={RulesSelecteced
                        && RulesSelecteced[RulesSelecteced.findIndex((rule, id) => rule.menu_id === item.id)]?.permissions.includes(Permissions[Permissions.findIndex((p, id) => p.name === 'create')].id)}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <p>Editar</p>
                    <Switch {...label}
                      onChange={() => handleChangeRules(profileSelecteced, item.id, Permissions[Permissions.findIndex((p, id) => p.name === 'edit')].id)}
                      checked={RulesSelecteced
                        && RulesSelecteced[RulesSelecteced.findIndex((rule, id) => rule.menu_id === item.id)]?.permissions.includes(Permissions[Permissions.findIndex((p, id) => p.name === 'edit')].id)}
                    />
                  </Box>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <p>Excluir</p>
                    <Switch {...label}
                      onChange={() => handleChangeRules(profileSelecteced, item.id, Permissions[Permissions.findIndex((p, id) => p.name === 'delete')].id)}
                      checked={RulesSelecteced
                        && RulesSelecteced[RulesSelecteced.findIndex((rule, id) => rule.menu_id === item.id)]?.permissions.includes(Permissions[Permissions.findIndex((p, id) => p.name === 'delete')].id)}
                    />
                  </Box>
                </Box>
              </Box>
            ))
          }
        </section>
      </Box>
    </MenuDrawer>
  )
}


