import styles from './SeeMoreFunctionary.module.css'
import React from 'react'
import { Avatar } from '@mui/material'
import { Head } from '../../shared'
import { MenuDrawer } from '../../components'
import ImageAvatar from '../../assets/images/avatar/2.jpg'

export function SeeMoreFunctionary() {
    return (
        <MenuDrawer>
            <Head title='Datalhes' />
            <section sx={{ m: 2 }}>
                <article className={styles.oneBloco}>
                    <div className={styles.bg}></div>
                    <Avatar src={ImageAvatar} alt='imagem-de-perfil' className={styles.avatar} />
                    <div className={styles.discriptioBloco}>
                        <p>Nome: Madrugada de Carvalho</p>
                        <p>Email: madrugadaoca@gmail.com</p>
                        <div>
                            <p>Graduado</p>
                            <p>UGS</p>
                        </div>
                    </div>
                </article>
                <article className={styles.twoBloco}>
                    <div className={styles.table}>
                        <h1 className='title'>Turmas</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{borderRadius: '1rem !important'}}>Turma</th>
                                    <th>Disciplina</th>
                                    <th>Turno</th>
                                    <th>Tempo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>TI12AT</td>
                                    <td>Lingua Portuguesa</td>
                                    <td>Tarde</td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                                <tr>
                                    <td>TI10CT</td>
                                    <td>SEAC</td>
                                    <td>Amanhã</td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                                <tr>
                                    <td>TI11BN</td>
                                    <td>TIC</td>
                                    <td>Amanhã</td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                                <tr>
                                    <td>TI12AT</td>
                                    <td>Lingua Portuguesa</td>
                                    <td>Tarde</td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                                <tr>
                                    <td>TI10CT</td>
                                    <td>SEAC</td>
                                    <td>Amanhã</td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                                <tr>
                                    <td>TI11BN</td>
                                    <td>TIC</td>
                                    <td>Amanhã</td>
                                    <td>Primeiro e Segundo</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.skills}>
                        <h1 className='title'>Adicionais</h1>
                        <ul>
                            <li>Graduado</li>
                            <li>Pós-Graduado</li>
                            <li>MBA</li>
                        </ul>
                    </div>
                </article>
            </section>
        </MenuDrawer>
    )
}
