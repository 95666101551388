import { api_services } from "./config";

const getAll = async (token) => {
    try {
        const url = `/country`
        
        const response = await api_services(url, {
            method: 'GET',
            headers: { 
                'content-Type': 'application-json', 
                Authorization: `Bearer ${token}`
            },
          });

          return response.json();
    } catch (error) {
        console.log({error});
        throw Error('problemas ao buscar os funcionarios')
    }
}

export const countryServices = {
    getAll,
}