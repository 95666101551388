import styles from './Footer.module.css';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from './../assets/images/logo-amarelo.png'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <section>
                <div className={styles.blockImage}>
                    <Link to='/'>
                        <img src={logo} alt='logo' />
                    </Link>
                </div>
                <div>
                    <h3>Páginas</h3>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/about'>Sobre</Link></li>
                        <li><Link to='/school'>Institutos</Link></li>
                        <li><Link to='/school'>Cursos disponíveis</Link></li>
                        <li><Link to='/school'>Orientação</Link></li>
                        <li><Link to='/school'>Calendário das Etapas</Link></li>
                    </ul>
                </div>
                <div>
                    <h3>Outros</h3>
                    <ul>
                        <li>Termos de Uso</li>
                        <li>Políticas de Privacidade</li>
                    </ul>
                </div>
            </section>
            <section>Todos os reitos reservados &copy; INFQI 2024</section>
        </footer>
    )
}

export default Footer
