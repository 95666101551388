import { PnoWrap } from '../paragraphy'
import styles from './Input.module.css'
import React from 'react'

const Input = ({ id, name, label, type, value, onChange, placeholder, errorMessage, ...rest }) => {
    return (
        <div className={styles.wrapper}>
            <label htmlFor={id}><PnoWrap color='grayText' text={label} /></label>
            <input 
                type={type} 
                {...rest} id={id} 
                name={name} 
                placeholder={placeholder} 
                className={!!errorMessage ? styles.errorMessageBorder: undefined}
                value={value??''} 
                onChange={onChange} 
            />
            {!!errorMessage && <small className={styles.errorMessage}>{errorMessage}</small>}
        </div>
    )
}

export default Input
