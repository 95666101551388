import React, { useMemo, useState } from 'react'
import { MenuDrawer, PnoWrap } from '../../components'
import { Environment, studentServices, Head, GET_CLASSROOMS } from '../../shared'
import {
  Box, LinearProgress, Pagination,
  Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
  Typography
} from '@mui/material'
import { useSearchParams, Link } from 'react-router-dom';
import Select from '../../components/formComponents/Select';
import { useAuthContext } from '../../shared/hooks';
import { PrivateSession } from '../../shared/hooks/usePermission';

export function Student() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalDatacount, setTotalDateCount] = React.useState(20);
  const [SearchParmams, setSearchParmams] = useSearchParams({ page: 1 });
  const [classroom, setClassRoom] = useState(false);
  const [classroomActive, setClassRoomActive] = useState(null);
  const [classroomSelected, setClassRoomSelected] = useState(false);
    const { token } = useAuthContext();

  const page = useMemo(() => {
    return SearchParmams.get('page') || ''
  }, [SearchParmams])
  
  const findAllClasses = async () => {
    try {
      const { url, options } = GET_CLASSROOMS(1, 100000000000000000, '', token);
      const response = await fetch(url, options);
      const result = await response.json();
      
      setClassRoom(result.data);
    } catch (error) {
      console.log('====================================');
      console.log({error});
      console.log('====================================');
    } finally {
      setLoading(false);
    }
  }

    React.useEffect(() => {
      localStorage.removeItem('@STUDENT_DTO');
      findAllClasses();
    }, [])
  
    // React.useEffect(() => {
    //   studentServices.getAll(page, 1000000000000000000000, '', undefined, token)
    //     .then((data) => {
    //       console.log({data});
          
    //     })
    //     .catch((error) => console.error(error))
    //     findAllClasses();
    // }, [])
    
  const deletestudent = async (id) => {
    try {
      await studentServices.delOne(id);
      alert('Alunos eliminado com sucesso')
    } catch (error) {
      console.log(error);
    }
  } 

  const onChandeClassroom = async (id) => {
    try {
      const response = await studentServices.getAllOneClassRoom(id, token);
      
      setData(response.data.students);
      setClassRoomActive(response.data.classroom[0]);
    } catch (error) {
      console.log(error);
    }
  }

  
  const saveStudantLocal = (id) => {
    
    const user = data[data.findIndex(el => el?.id === id)];
    console.log({user, id, find: data.findIndex(el => el?.students?.id === id)});
    
    localStorage.setItem('@STUDENT_DTO', JSON.stringify({user, classroomActive}))
  }

  return (

    <MenuDrawer page='Alunos'>
      <Head title='Alunos' />
      <Box component={Paper} py={2} m={2} flex={1}>
        <Box m={1} ml={2}>
          <section className='bloco-pesquisa-botao'>
            <PrivateSession menu={["student_TM"]} permissionRoute={1}>
            <div className='search'>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={classroomSelected}
                        values={classroom}
                        onChange={(e) => {onChandeClassroom(e.target.value); setClassRoomSelected(e.target.value)} }
                    />
                </div>
                <div className='search'>
              <input type='text' placeholder='Pesquisar' />
              <button><i className='fas fa-search'></i></button>
            </div>
            </PrivateSession>
                
            <PrivateSession menu={["student_TM"]} permissionRoute={2}>
              <Link to='/people/students/register' className='btn btn-add'>
                Cadastrar Aluno <i className='fas fa-plus ml-1'></i>
              </Link>
            </PrivateSession>
          </section>
        </Box>

        <PrivateSession menu={["student_TM"]} permissionRoute={1}>
          <Box m={1} ml={2}>
            <Box marginBottom={5}>
              <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                  <Table sx={{ minWidth: 650, color: '#333', background: '#E19E06 !importtant' }} size='small' aria-label="a dense table">
                    <TableHead style={{ background: '#E19E06 !importtant'}}>
                      <TableRow>
                        <TableCell className='title-table text-center'>Nº de Processo</TableCell>
                        <TableCell className='title-table text-center'>Nome Completo</TableCell>
                        <TableCell className='title-table text-center'>Curso</TableCell>
                        <TableCell className='title-table text-center'>Número de Idêntidade</TableCell>
                        <TableCell className='title-table text-center'>Acções</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.length > 0 &&
                        data.map((item) => (
                          <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell className='text-center'><PnoWrap color='black' text={item.process_number}/></TableCell>
                            <TableCell className='text-center'><PnoWrap color='black' text={item.userName} /></TableCell>
                            <TableCell className='text-center'><PnoWrap color='black' text={classroomActive.courseName} /></TableCell>
                            <TableCell className='text-center'><PnoWrap color='black' text={item?.documentNumber} /></TableCell>

                            <TableCell align="center">
                              <Box display={'flex'} gap={1} justifyContent={'center'}>
                                <PrivateSession menu={["student_TM"]} permissionRoute={1}>
                                  <Link className='btn-icon' to={`/people/students/show/${item.id}`} onClick={() => saveStudantLocal(item.id)}>
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </PrivateSession>
                                <PrivateSession menu={["student_TM"]} permissionRoute={3}>
                                  <Link to={`/people/students/update/${item.id}`}>
                                    <Typography className='btn-icon btn-edit'>
                                      <i className="fa-solid fa-edit"></i>
                                    </Typography>
                                  </Link>
                                </PrivateSession >
                                <PrivateSession menu={["student_TM"]} permissionRoute={5}>
                                  <Typography className='btn-icon btn-edit' onClick={() => deletestudent(item.id)}>
                                    <i className="fa-solid fa-trash"></i>
                                  </Typography>
                                </PrivateSession >

                                <PrivateSession menu={["student_TM"]} permissionRoute={4}>
                                  <Link className='btn-icon' to={`/people/students/update/${item.id}`}>
                                    <i className="fa-solid fa-print"></i>
                                  </Link>
                                </PrivateSession >  
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      }

                      {loading && (
                        <TableRow>
                          <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                        </TableRow>)
                      }

                    </TableBody>
                    <TableFooter>
                      {data.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={12}><PnoWrap color='grayText' text="Selecione a turma nas opções acima"/></TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                          <Pagination
                            count={Math.ceil(totalDatacount / Environment.ROW_LIMIT)}
                            page={Number(page)} onChange={(_, newPage) => setSearchParmams({ page: newPage.toString() }, { replace: true })}
                            color='primary'
                            size='small'
                          />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>

            </Box>
          </Box>
        </PrivateSession >
      </Box>

    </MenuDrawer>
  )
}
