import { API } from "./api"
import { api_services } from "./config"

export const GET_DISCIPLINE = (page=1, limit=15, filter='', token = '') => {
    return {
        url: `${API}/subjects?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
        }
    }
}

const POST_subjects = async (data, token='') => {
    try {
        const result = await api_services('/subjects', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message);
    }
}

const updateDiscipline = async (id, data, token='') => {
    try {
        const result = await api_services(`/subjects/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message);
    }
}

const getOne = async (id, token) => {
    try {
        const result = await api_services(`/subjects/${id}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            },
        })
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getAllDeleted = async (page=1, limit=15, filter='', token = '') => {
    try {
        const result = await api_services(`/subjects/deleted_at?filter=${filter}&per_page=${limit}&page=${page}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            },
        })
        return result;
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreAllDeleted = async (token) => {
    try {
        const result = await api_services(`/subjects/restore_all`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`  },
        })
        return result;
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreOneDeleted = async (id, token) => {
    try {
        const result = await api_services(`/subjects/restore_one/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`  },
        })
        return result;
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}
const DeleteOne = async (id, token) => {
    console.log(id);

    try {
        const result = await api_services(`/subjects/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`  },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const DisciplineService = {
    POST_subjects,
    getOne,
    DeleteOne,
    updateDiscipline,
    getAllDeleted,
    restoreAllDeleted,
    restoreOneDeleted
}