import { api_services } from "./config";

// const getAll = async (page = 1, limit = 15, filter = '', id = undefined, token) => {
//     try {
//         const url = `/students?_page=${page}&_limit=${limit}&_filter=${filter}&_id=${id}`
        
//         const response = await api_services(url, {
//             method: 'GET',
//             headers: { 
//                 'content-Type': 'application-json',
//                 Authorization: `Bearer ${token}`
//              },
//           });

//           return response.data;
//     } catch (error) {
//         console.log({error});
//         throw Error('problemas ao buscar os alunos')
//     }
// }

const getAllOneClassRoom = async (classroom, token) => {
    try {
        const url = `/auth/list-students/${classroom}`
        
        const response = await api_services(url, {
            method: 'GET',
            headers: { 
                'content-Type': 'application-json',
                Authorization: `Bearer ${token}`
             },
          });
          
          return response.json();
    } catch (error) {
        console.log({error});
        throw Error('problemas ao buscar os alunos')
    }
}

// const getOne = async ( id = undefined ) => {
//     try {
//         const response = await api_services.get(`/students/${id}`, {
//             method: 'GET',
//             headers: { 'content-Type': 'application-json', },
//           });

//           return response.data;
//     } catch (error) {
//         console.log({error});
//         throw Error('problemas ao buscar o aluno')
//     }
// }

// const delOne = async ( id = undefined ) => {
//     try {
//         const response = await api_services.delete(`/students/${id}`);

//           return response.data;
//     } catch (error) {
//         console.log({error});
//         throw Error('problemas ao deletar o aluno')
//     }
// }

// const post = async ( data ) => {
//     try {
//         const response = await api_services.post(`/students`, JSON.stringify(data));

//           return response.data;
//     } catch (error) {
//         console.log({error});
//         throw Error('problemas ao salvar o aluno')
//     }
// }


export const studentServices = {
    // getAll,
    // getOne,
    // delOne,
    // post,
    getAllOneClassRoom,
}