import { Box, Paper } from '@mui/material'
import React from 'react'
import { PnoWrap } from '../paragraphy'

export function CardMinimalCourseInfo({ account = '', description = '', colorD = undefined, ...rest }) {
  return (
    <Box component={Paper} p={2} height={100} {...rest}>
      <div style={{ marginBottom: '1rem', marginTop: '.5rem' }}>
        <PnoWrap fontSize={14} color={colorD ?? 'grayText'} text={description} />
        <h1 className='title'>{account}</h1>
      </div>
    </Box>
  )
}
