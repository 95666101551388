import { API } from "./api"
import { api_services } from "./config"

export const GET_COURSES = (page=1, limit=15, filter='', token = '') => {
    return {
        // &page=${page}&limit=${limit}
        url: `${API}/courses?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json' ,
                Authorization: `Bearer ${token}`
            }
        }
    }
}

export const GET_COURSES1 = (filter='', filter2 = '', token = '') => {
    return {
        url: `${API}/courses/all/schools?filter=${filter}&filter2=${filter2}`,
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json' ,
                Authorization: `Bearer ${token}`
            }
        }
    }
}
export const GET_TRAINIG = (page=1, limit=15, filter='', token = '') => {
    return {
        url: `${API}/training_areas?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json' ,
                Authorization: `Bearer ${token}`
            }
        }
    }
}
export const GET_COURSES_DELETED = (page=1, limit=15, filter='', token = '') => {
    return {
        url: `${API}/courses/deleted_at?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` 
            }
        }
    }
}
export const getAllCourseJoinDiscipline = (filter='', token = '') => {
    return {
        url: `${API}/course_has_subjects?filter=${filter}`,
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
    }
}
const POST_COURSES = async (data, token) => {
    try {
        const result = await api_services('/courses', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const POST_TRAINING = async (data, token) => {
    try {
        const result = await api_services('/training_areas', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const POST_TRAINING_vacancies = async (data, token) => {
    try {
        const result = await api_services('/VacanciesTrainingArea', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result;
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const POST_TRAINING_discipline = async (data, token) => {
    try {
        const result = await api_services('/SubjectExamAccess', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const post_user_discipline_note = async (data, token) => {
    try {
        const result = await api_services('/ExamGradeAccess', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result;
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const put_user_discipline_note = async (data, id, token) => {
    try {
        const result = await api_services(`/ExamGradeAccess/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result;
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const put_TRAINING_vacancies = async ({number_vacancies}, id, token) => {
    try {
        const result = await api_services(`/VacanciesTrainingArea/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({number_vacancies}),
        })
        return result;
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const get_TRAINING_discipline = async (id, token) => {
    try {
        const result = await api_services(`/SubjectExamAccess/list?filter=${id}`, {
            method: 'GET',
            headers: {
                // 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        return result.json();
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const set_media_all_candidate = async (token) => {
    try {
        const result = await api_services(`/Generate_media`, {
            method: 'GET',
            headers: {
                // 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        return result.json();
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const PUT_TRAINING = async (data, id, token) => {
    try {
        const result = await api_services(`/training_areas/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const createJoinCourseOfDiscipline = async (data, token) => {   
    console.log({token});
     
    try {
        const result = await api_services('/course_has_subjects', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}
const deleteJoinCourseOfDiscipline = async (data, token) => {
    console.log({DATA: data});
    
    try {
        const result = await api_services('/course_has_subjects/destroy', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result;
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}
const updateCourse = async (id, data, token) => {
    console.log(id);
    try {
        const result = await api_services(`/courses/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        return result.json();
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const getOne = async (id, token) => {
    try {
        const result = await api_services(`/courses/${id}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` },
            })
        return result.json();
    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const getAllDeleted = async (token) => {
    try {
        const result = await api_services(`/courses/deleted_at`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
                
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const getAllTrainingArea = async (token) => {
    try {
        const result = await api_services(`/training_areas`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        return Error(error.message)
    }
}

const restoreAllDeleted = async (token) => {
    try {
        const result = await api_services(`/courses/restore_all`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
             },
        })

        return result;

    } catch (error) {
        console.log(error);
        return new Error(error.message)
    }
}

const restoreOneDeleted = async (id, token) => {
    try {
        const result = await api_services(`/courses/restore_one/${id}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
             },
        })

        console.log({result});
         
        return result;

    } catch (error) {
        console.log(error);
        return new Error(error.message)
    }
}

const DeleteOne = async (id, token) => {
    try {
        const result = await api_services(`/courses/${id}`, {
            method: 'DELETE',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` },
            })

        return result;

    } catch (error) {
        console.log(error);
        return new Error(error.message)
    }
}

export const courseService = {
    POST_COURSES,
    getOne,
    DeleteOne,
    updateCourse,
    getAllDeleted,
    restoreAllDeleted,
    restoreOneDeleted,
    getAllCourseJoinDiscipline,
    createJoinCourseOfDiscipline,
    deleteJoinCourseOfDiscipline,
    getAllTrainingArea,
    POST_TRAINING,
    GET_TRAINIG,
    PUT_TRAINING,
    POST_TRAINING_vacancies,
    put_TRAINING_vacancies,
    get_TRAINING_discipline,
    POST_TRAINING_discipline,
    post_user_discipline_note,
    put_user_discipline_note,
    set_media_all_candidate
}