import styles from './Curso.module.css'
import React, { useEffect, useMemo } from 'react'
import { Input, MenuDrawer, PnoWrap } from '../../components'
import { classroomService, Environment, Head } from '../../shared'
import {
  Box, Button, Drawer, Icon, LinearProgress, Pagination,
  Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
  Typography, useTheme
} from '@mui/material'
import {  useSearchParams } from 'react-router-dom';
import { courseService } from '../../shared/services/course';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../shared/hooks';

import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import Select from '../../components/formComponents/Select'


const schema = yup.object().shape({
    name: yup.string().required('nome é obrigatório'),
    min_students: yup.number(),
    max_students: yup.number(),
    start_date: yup.date().notRequired(),
    end_date: yup.date().required('campo obrigatório'),
    training_area_id: yup.number(),
})



export function Exam_class() {
  const [data, setData] = React.useState([]);
  const [Training, setTraining] = React.useState([]);
  const [isUpdate, setIsUpdate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [Change, setChange] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [totalAccount, setTotalAccount] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useAuthContext()
  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
      resolver: yupResolver(schema), defaultValues: {
          name: "",
          end_date: undefined,
          max_students: undefined,
          min_students: undefined,
          start_date: undefined,
          training_area_id: undefined
      }
  })


  const search = useMemo(() => {
    return searchParams?.get('search') || ''
  }, [searchParams])

  const page = useMemo(() => {
    return searchParams?.get('page') || 1
  }, [searchParams])
  const { palette } = useTheme();

  React.useEffect(() => {
    const findAllCourses = async () => {
      try {
        setLoading(true);
        const response = await classroomService.get_classrooms_exam(token ?? '');
         
        console.log({response});
        
        setData(response.data??[]);
        setTotalAccount(response.meta.total)
      } catch (error) {
        if (error.message === 'Failed to fetch')
          toast.error('Problemas de conexão com a internet!')
        toast.error(error.message)

      } finally {
        setLoading(false);
      }
    }
    findAllCourses();
    setChange(false)
  }, [search, page, Change]);

  useEffect(() => {
    const trainingArea = async () => {
          try {
            setLoading(true);
            const { url, options } = courseService.GET_TRAINIG(1, 99999999999999999, '', token ?? '');
            const response = await fetch(url, options);
            const result = await response.json();
            
            setTraining(result.data??[]);
          } catch (error) {
            if (error.message === 'Failed to fetch')
              toast.error('Problemas de conexão com a internet!')
    
          } finally {
            setLoading(false);
          }
        }
        trainingArea();
  }, [])
  

  const toggleDrawer = (id = undefined) => {
    if(!id) {
      setOpenDrawer(!openDrawer)
    }else {
      setIsUpdate(id)
      setOpenDrawer(!openDrawer)
    }
  }

  const Submit = async (data) => {
    const { name, end_date, max_students, min_students, start_date, training_area_id } = data;
    console.log({name, end_date: new Date(end_date).toLocaleDateString('pt-BR').replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3/$2/$1'), max_students, min_students, start_date: new Date(start_date).toLocaleDateString('pt-BR').replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3/$2/$1'), training_area_id});
    
    try {
      const result = await classroomService.POST_classrooms_exam({name, end_date: new Date(end_date).toLocaleDateString('pt-BR').replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3/$2/$1'), max_students, min_students, start_date: new Date(start_date).toLocaleDateString('pt-BR').replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3/$2/$1'), training_area_id}, token)

      if(result.errors){
        console.log({e: result.errors});
        
        toast.error(JSON.stringify(result))
        return;
      }


      console.log({result});

      setChange(true)
      setError(null)
      toast.success('Sala cadastrada com sucesso.')
      toggleDrawer()
    } catch (error) {
      console.log({error});
    }
  }

  const handleUpdate = async (data) => {
    
    try {
      const result = await classroomService.PUT_classrooms_exam(data, token)

      // if(result.message === "The name has already been taken."){
      //   toast.error('Área de formação já se encontra Cadastrada!!!')
      //   return;
      // }

      console.log({result});
      
      setChange(true)
      setError(null)
      toast.success('Area de formação Actualizada com sucesso.')
      setIsUpdate(null)
      toggleDrawer()
    } catch (error) {
      console.log({error});
    }
  }

  return (
    <MenuDrawer page='Área de Formação'>
      <Head title='Área de Formação' />
      <Box component={Paper} py={2} m={2} flex={1}>
        <Box m={1} ml={2}>
          <section className='bloco-pesquisa-botao'>
            <div className='search'>
              <input type='text' placeholder='Pesquisar'
                onChange={(evt) => { setSearchParams({ search: evt.target.value }, { replace: true }) }}
                value={search ?? ''} />
              <button><i className='fas fa-search'></i></button>
            </div>

            <Button className='btn btn-add' onClick={() => toggleDrawer()}>
              Cadastrar  <i className='fas fa-plus ml-1'></i>
            </Button>
          </section>
          <Drawer anchor='right' open={openDrawer} variant='temporary' >  
            <Box width={300} pt={10} px={2} component={'form'} noValidate onSubmit={handleSubmit(Submit)}>
              <Box display={'flex'} justifyContent={'space-between'}>
                {isUpdate ? <PnoWrap color='black' text='Actualizar' />:<PnoWrap color='black' text='Cadastrar' />}

                <Icon onClick={() => toggleDrawer()}>close</Icon>
              </Box>
              <Controller
                control={control}
                name='name'
                render={({field: {onChange, value}}) => (
                  <Box my={2}>
                    <Input label={'Sala'} errorText={errors.name?.message} error={!!errors.name?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                  </Box>
                )}
                />

              <Controller
                control={control}
                name='min_students'
                render={({field: {onChange, value}}) => (
                  <Box my={2}>
                    <Input label={'Nº minímo de estudante'} type={'number'} errorText={errors.min_students?.message} error={!!errors.min_students?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                  </Box>
                  )}
                />


              <Controller
                control={control}
                name='max_students'
                render={({field: {onChange, value}}) => (
                  <Box my={2}>
                    <Input label={'Nº maxímo de estudante'} type={'number'} errorText={errors.max_students?.message} error={!!errors.max_students?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                  </Box>
                  )}
                />

                  <Box>
                    {/* <PnoWrap text={'Área de formação'} color='grayText'/> */}
                    <Controller
                      control={control}
                      name='training_area_id'
                      render={({ field: { value, onChange } }) => (
                          <Box display={'flex'} flexDirection={'column'} style={{ width: '100%' }}>
                              <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={value ?? ''}
                                  label="Selecione Área de formação"
                                  values={Training}
                                  onChange={(e) => onChange(e.target.value) }
                              />
                              {errors.training_area_id?.message &&
                                  <Typography color={'red'} fontSize={12}>
                                      {errors.location_id?.message}
                                  </Typography>
                              }
                          </Box>
                      )}
                  />
                  </Box>


              <Controller
                control={control}
                name='start_date'
                render={({field: {onChange, value}}) => (
                  <Box my={2}>
                    <PnoWrap text={'Data de inicio'} color='grayText'/>
                    <Input type={'date'} errorText={errors.start_date?.message} error={!!errors.start_date?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                  </Box>
                  )}
                />

              <Controller
                control={control}
                name='end_date'
                render={({field: {onChange, value}}) => (
                  <Box my={2}>
                    <PnoWrap text={'Data de fim'} color='grayText'/>
                    <Input type={'date'} errorText={errors.end_date?.message} error={!!errors.end_date?.message} value={value??''} onChange={(e) => onChange(e.target.value)}/>
                  </Box>
                  )}
                />

              {isUpdate ? <Button variant='contained' color='secondary' onClick={() => handleUpdate()}>
                Actualizar
              </Button>:
              <Button variant='contained' type='submit'>
                Salvar
              </Button>}
            </Box>
          </Drawer>
          <Box marginBottom={5}>
            <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
              <TableContainer component={Paper} elevation={0} sx={{ flexGrow: 1 }} style={{ border: '1px solid #ededed' }}>
                <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                  <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                    <TableRow>
                      <TableCell className='title-table text-center'>Sala</TableCell>
                      <TableCell className='title-table text-center'>Área de Formação</TableCell>
                      <TableCell className='title-table text-center'>Min. Estudante</TableCell>
                      <TableCell className='title-table text-center'>Max. Estudante</TableCell>
                      <TableCell className='title-table text-center'>Data de Abertura</TableCell>
                      <TableCell className='title-table text-center'>Data de Fechamento</TableCell>
                      <TableCell className='title-table text-center'>Acções</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0 ?
                      data.map((item) => (
                        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className='text-center cel-name'>{item.name}</TableCell>
                          <TableCell className='text-center'>
                            {}
                          </TableCell>
                          <TableCell className='text-center'>
                            {item.min_students}
                          </TableCell>
                          <TableCell className='text-center'>
                            {item.max_students}
                          </TableCell>
                          <TableCell className='text-center'>
                            {new Date(item.start_date).toLocaleDateString('pt-PT')}
                          </TableCell>
                          <TableCell className='text-center'>
                            {new Date(item.end_date).toLocaleDateString('pt-PT')}
                          </TableCell>
                          <TableCell align="center">
                            <Box display={'flex'} gap={1} justifyContent={'center'}>
                              <Typography className='btn-icon btn-edit' onClick={() => {toggleDrawer(item.id)}}>
                                <i className="fa-solid fa-edit"></i>
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )) : (
                        <TableRow>
                          <TableCell colSpan={12} align="center"><Typography width={250}>Lista de Cursos Vazia</Typography></TableCell>
                        </TableRow>
                      )
                    }

                    {loading && (
                      <TableRow>
                        <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                      </TableRow>)
                    }

                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                        <Pagination
                          count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                          page={Number(page)} onChange={(_, newPage) => { setSearchParams({ _, page: newPage.toString() }, { replace: true }) }}
                          color='primary'
                          size='small'
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </MenuDrawer>
  )
}
