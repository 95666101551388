import React, { useMemo } from 'react'
import { MenuDrawer, PnoWrap } from '../../components'
import { Environment, Head } from '../../shared'
import {
  Box, Button, Divider, Grid, IconButton, InputBase, LinearProgress, Pagination,
  Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
  Typography, useTheme
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { LocationService, GET_LOCATION } from '../../shared/services';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';

export function LocationDeleted() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const navigate = useNavigate()
  const { palette } = useTheme();
  const { token } = useAuthContext();
  const [searchParams, setSearchParams] = useSearchParams()
  const [totalAccount, setTotalAccount] = React.useState(0);

  const search = useMemo(() => searchParams.get('search') || '', [searchParams])

  const page = useMemo(() => searchParams.get('page') || 1, [searchParams])
  const [Change, setChange] = React.useState(false);

  React.useEffect(() => {
    const findAllLocations = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await LocationService.getAllDeleted(page, Environment.ROW_LIMIT, search, token);
        setData(response.data);
        setTotalAccount(response.meta.total)
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false);
      }
    }
    findAllLocations();
    setChange(false)
  }, [search, page,Change]);

 
  const restoreOne = async (id) => {
    const result = await LocationService.restoreOneDeleted(id);
    if (result instanceof Error){
    console.log(result)
    setError(result.message);
    toast.error(result.message)
    setLoading(false);
    } else {
    
    console.log(result);
    toast.success('Curso restaurado com sucesso!', {
        position: 'top-right',
        className: 'custom-toast',
    })
    setChange(true)
    setLoading(false);
    }
}

const restoreAllCourse = async () => {
    setLoading(true);
    setError(null);
    const response = await LocationService.restoreAllDeleted();
    if (response instanceof Error){
      console.log(response)
      setError(response.message);
      toast.error(response.message)
      setLoading(false);
    } else {
      
      console.log(response);
      toast.success('Todos os Localizações restaurados com sucesso!', {
        position: 'top-right',
       className: 'custom-toast',
      })
      navigate('/academic/courses')
      setLoading(false);
    }
  }

  return (
    <MenuDrawer page='Lozalizações Apagadas'>
      <Head title='Lozalizações' />
      <Box component={Paper} py={2} m={2} flex={1}>
        <Box m={1} ml={2}>
          <Box m={1} >
            <Grid container sx={{ display: 'flex', alignItems: 'center', py: 2 }} spacing={1} gap={1} >
              <Grid item>
                <Paper
                  component="form"
                  sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 250, border: .1, borderColor: 'gray' }}
                >
                  <InputBase
                    size='small'
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Pesquisar"
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={(evt) => { setSearchParams({search: evt.target.value}, { replace: true})}}
                    value={search??''}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid item>
                <button className='btn-success border-none' onClick={() => restoreAllCourse()}>
                  Restaurar todas as localizações <i className='fas fa-plus'></i>
                </button>
              </Grid>
            </Grid>
            <Divider />
          </Box>
          <Box marginBottom={5}>
            <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
              <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                  <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                    <TableRow>
                    <TableCell className='title-table text-center'>Codigo</TableCell>
                    <TableCell className='title-table text-center'><PnoWrap text='Nome do local'/></TableCell>
                      <TableCell className='title-table text-center'>Localozação</TableCell>
                      <TableCell className='title-table text-center'>Estado</TableCell>
                      <TableCell className='title-table text-center'>Acções</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0 ?
                      data.map((item) => (
                        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className='text-center'>{item.code}</TableCell>
                          <TableCell className='text-center cel-name'>{item.name}</TableCell>
                          <TableCell className='text-center cel-name'>{item.address}</TableCell>
                          <TableCell className='text-center'>
                              <p className='curso-inactivo'>Apagado</p>
                          </TableCell>
                          <TableCell align="center">
                            <Box display={'flex'} gap={1} justifyContent={'center'}>
                              <Typography className='btn-icon btn-edit' onClick={() => restoreOne(item.id)}>
                                <i className="fa-solid fa-refresh"></i>
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )) : (
                        <TableRow>
                          <TableCell colSpan={12} align="center"><Typography width={250}>Lista de Localizações Vazia</Typography></TableCell>
                        </TableRow>
                      )
                    }

                    {loading && (
                      <TableRow>
                        <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                      </TableRow>)
                    }

                  </TableBody>
                  <TableFooter>
                    {error && (
                      <TableRow>
                        <TableCell colSpan={12} align="center"><Typography width={250}>{error}</Typography></TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                        <Pagination
                          count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                          page={Number(page)} onChange={(_, newPage) => setSearchParams({ _, page: newPage.toString() }, { replace: true })}
                          color='primary'
                          size='small'
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </MenuDrawer>
  )
}
