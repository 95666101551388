import React, { useMemo } from 'react'
import { MenuDrawer } from '../../components'
import { Environment, Head } from '../../shared'
import {
    Box, FormControl, Grid, Icon, InputLabel, LinearProgress, MenuItem, Pagination,
    Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    Typography, useTheme
} from '@mui/material'


// import MP from ''
import { Link, useSearchParams } from 'react-router-dom';
import { schoolService } from '../../shared/services';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';

export function Pauta() {
    const { palette } = useTheme();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [totalAccount, setTotalAccount] = React.useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const { token } = useAuthContext()
    const [Change, setChange] = React.useState(false);

    const MP = require('../../assets/Mini_Pauta.pdf')
    const search = useMemo(() => {
        return searchParams?.get('search') || ''
    }, [searchParams])

    const page = useMemo(() => {
        return searchParams?.get('page') || 1
    }, [searchParams])

    // const findAllSchools = async () => {
    //     try {
    //         setLoading(true);
    //         setError(null);
    //         const { url, options } = schoolService.GET_school(page, Environment.ROW_LIMIT, search ?? '', token ?? '')
    //         const response = await fetch(url, options)
    //         const result = await response.json();
    //         console.log({ result });

    //         setData(result.data);
    //         setTotalAccount(result.meta.total)
    //     } catch (error) {
    //         setError(error)
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    // React.useEffect(() => {
    //     findAllSchools();
    // }, [search, page, Change])



    return (
        <MenuDrawer page='Mini - Pauta'>
            <Head title='Pautas' />

            <Box component={Paper} py={2} m={2} display={'flex'} flexDirection={'column'}>
                
                <Box m={1} ml={2}>
                    <section className='bloco-pesquisa-botao'>
                        <Grid container spacing={2}>
                        
                            <Grid item>
                                <div className='search'>
                                    <input type='text' placeholder='Pesquisar'
                                        onChange={(evt) => { setSearchParams({ search: evt.target.value }, { replace: true }) }}
                                        value={search ?? ''} />
                                    <button><i className='fas fa-search'></i></button>
                                </div>
                            </Grid>
                            
                            <Grid item>
                                <Box >
                                    <FormControl sx={{ minWidth: 250, maxWidth: 250 }} size="small">
                                        <InputLabel id="demo-simple-select-label" sx={{ display: 'flex', gap: 1 }}>Selecione o Trimestre <Icon>book</Icon></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            size='small'
                                            id="demo-simple-select"
                                            value={''}
                                            label="Selecione o Trimestre"
                                            sx={{ py: .5 }}
                                        >
                                            <MenuItem value={10}>1º Trimestre</MenuItem>
                                            <MenuItem value={20}>2º Trimestre</MenuItem>
                                            <MenuItem value={30}>3º Trimestre</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Box >
                                    <FormControl sx={{ minWidth: 250, maxWidth: 250 }} size="small">
                                        <InputLabel id="demo-simple-select-label" sx={{ display: 'flex', gap: 1 }}>Selecione o Turma <Icon>school</Icon></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            size='small'
                                            id="demo-simple-select"
                                            value={''}
                                            label="Selecione o Turma"
                                            sx={{ py: .5 }}
                                        >
                                            <MenuItem value={10}>A</MenuItem>
                                            <MenuItem value={20}>B</MenuItem>
                                            <MenuItem value={30}>C</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Box>
                                    <Link to='#' className='btn btn-add'>
                                        Criar <i className='fas fa-plus ml-1'></i>
                                    </Link>
                                </Box>
                            </Grid>

                            {/* <Grid item>
                                <Box>
                                    <Link to='#' className='btn btn-success'>
                                        Fechar Trimestre <i className='fas fa-trash ml-1'></i>
                                    </Link>
                                </Box>
                            </Grid> */}
                        </Grid>
                    </section>
                </Box>
                 <Box m={1} ml={2}>
                    <Box marginBottom={5}>
                        <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                            <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                                <Table sx={{ minWidth: 650 }} size='small' aria-label="a dense table">
                                    <TableHead sx={{ backgroundColor: `${palette.primary.main}`}}>
                                        <TableRow>
                                            <TableCell className='title-table text-center'>Turma</TableCell>
                                            <TableCell className='title-table text-center'>Trimestre</TableCell>
                                            <TableCell className='title-table text-center'>Estado</TableCell>
                                            <TableCell className='title-table text-center'>Acções</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.length > 0 && data.map((item) => (
                                                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell className='text-center'>{item.name}</TableCell>
                                                    <TableCell className='text-center'>{item.user.name}</TableCell>
                                                    <TableCell className='text-center'>{item.address}</TableCell>
                                                    <TableCell align="center">
                                                        <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                            <Link className='btn-icon' to={`/academic/school/show/${item.id}`}>
                                                                <i className="fa-solid fa-eye"></i>
                                                            </Link>
                                                            <Link to={`/academic/school/update/${item.id}`}>
                                                                <Typography className='btn-icon btn-edit'>
                                                                    <i className="fa-solid fa-edit"></i>
                                                                </Typography>
                                                            </Link>
                                                            <Link className='btn-icon' to={`/academic/school/print/${item.id}`}>
                                                                <i className="fa-solid fa-print"></i>
                                                            </Link>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                        {loading && (
                                            <TableRow>
                                                <TableCell colSpan={10} align="center">
                                                    <LinearProgress color='secondary' />
                                                </TableCell>
                                            </TableRow>)
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        {data.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={12} align="center">
                                                    <Typography width={250}>Lista de Escolas vazia</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                                                <Pagination
                                                    count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                                                    page={Number(page)} onChange={(_, newPage) => { setSearchParams({ _, page: newPage.toString() }, { replace: true }) }}
                                                    color='primary'
                                                    size='small'
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
                
            </Box>
        </MenuDrawer>
    )
}
