import { Typography } from '@mui/material'
import React from 'react'

export function PnoWrap({ text, color = 'white', ...rest }) {
  return (
    <Typography noWrap textOverflow={'ellipsis'} color={color} {...rest}>
      {text}
    </Typography>
  )
}
