import * as yup from 'yup';
import React, { useEffect, useState } from 'react'
import {
    Box, Button, CircularProgress, Container,
    FormControl,
    Grid,
    Icon,
    InputLabel, MenuItem, Paper, Select, useTheme
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from '../form';
import { LocationService, Head } from '../../shared'
import { MenuDrawer } from '../Dashboard';
import { useParams } from 'react-router-dom';
import { ErrorResponse, SuccessResponse } from '../Modals';


const schema = yup.object().shape({
    name: yup.string().required('Campo obrigatorio'),
    code: yup.string().required('Campo obrigatorio'),
    user_id: yup.number().required('Campo obrigatorio').moreThan(0),
    description: yup.string().optional(),
    address: yup.string().required('Campo obrigatorio'),
    status: yup.number().optional(),
    sort_order: yup.number().optional(),
})

export function AddAndUpdateLocation() {
    const params = useParams()
    const [isUpdate, setIsUpdate] = useState(false);

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            name: '',
            description: '',
            code: '',
            address: '',
            status: 0,
            sort_order: undefined,
        }
    });

    const [Loading, setLoading] = useState(false);
    const [MsgSuccess, setMsgSuccess] = useState(undefined);
    const [MsgError, setMsgError] = useState(undefined);

    const [LocationUpdate, setLocationUpdate] = useState(null);

    useEffect(() => {
        if (params && params.id && typeof Number(params.id) === 'number') {
            setIsUpdate(true)
            LocationService.getOne(params.id).then((data) => {
                setLocationUpdate(data.data)
                
            }).catch(err => {
                console.log({ err });
            })
        }
    }, [params, isUpdate])

    useEffect(() => {
        if (LocationUpdate) {
            setValue('name', LocationUpdate.name);
            setValue('description', LocationUpdate.description);
            setValue('sort_order', LocationUpdate.sort_order);
            setValue('code', LocationUpdate.code);
            setValue('status', LocationUpdate.status);
            setValue('address', LocationUpdate.address);
        }

    }, [LocationUpdate]);

    const cleanData = () => {
        setValue('name', '');
        setValue('description', '');
        setValue('sort_order', '');
        setValue('years', '');
        setValue('status', '');
        setValue('code', '');
        setValue('address', '');
    }

    const submitData = async (data) => {
        setLoading(true)
        try {
            const response = await LocationService.POST_LOCATION(data);
            cleanData()
            console.log(response);
            setMsgSuccess('Location cadastrado com sucesso.');
        } catch (error) {
            console.log(error)
            setMsgError(error.error)
        }finally{
            setLoading(false)
        }
    }
 
    const UpdateData = async (data) => {
        setLoading(true)        
        try {
            const response = await LocationService.updateLocation(params.id, data);
            console.log(response);
            cleanData();
            setMsgSuccess('Location actualizado com sucesso.');
        } catch (error) {
            console.log(error)
            setMsgError(error.error)
        }finally{
            setLoading(false)
        }
    }

    return (
        <MenuDrawer page='Locations'>
            <Head title={isUpdate ? 'Actualizar dados do Location' : 'Cadastra Location'} />
            {!!MsgSuccess && <SuccessResponse title={MsgSuccess} setClose={setMsgSuccess} urlNext='/academic/Locations' />}
            
            {!!MsgError && <ErrorResponse title={MsgError} setClose={setMsgError} />}
           
           <Box component={Paper} m={2} borderRadius={2}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        {!isUpdate
                            ? (
                                <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Cadastro</h1>
                                    <p>Preencha todos os campos para efectuar o cadastro</p>
                                </div>
                            )
                            : (
                                <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Actualizar</h1>
                                    <p>Edite todos os dados desejado para que a actualização seja bem sucedida</p>
                                </div>
                            )
                        }
                        <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={!!isUpdate ? handleSubmit(UpdateData) : handleSubmit(submitData)}>
                            <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <InputLabel className='label'>Nome do Local</InputLabel>
                                        <Controller
                                            control={control}
                                            name='name'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    margin="normal"
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={"Digite o nome do funcionário"}
                                                    color='primary'
                                                    error={!!errors.name}
                                                    helperText={errors.name ? errors.name.message : ''}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <InputLabel className='label'>Código</InputLabel>
                                        <Controller
                                            control={control}
                                            name='code'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    margin="normal"
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={"Digite o nome do funcionário"}
                                                    color='primary'
                                                    error={!!errors.code}
                                                    helperText={errors.code ? errors.code.message : ''}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <InputLabel className='label'>Endereço</InputLabel>
                                        <Controller
                                            control={control}
                                            name='address'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    margin="normal"
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={"Digite o nome do funcionário"}
                                                    color='primary'
                                                    error={!!errors.address}
                                                    helperText={errors.address ? errors.address.message : ''}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Controller
                                            control={control}
                                            name='status'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                
                                                <>
                                                    <InputLabel className='label'>Estado</InputLabel>
                                                    <FormControl fullWidth 
                                                    margin="normal">

                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.status}
                                                        label='Selecione'
                                                    >
                                                        <MenuItem key={1} value={1??''}>Activo</MenuItem>
                                                        <MenuItem key={0} value={0??''}>Inativo</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <Controller
                                            control={control}
                                            name='user_id'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                
                                                <>
                                                    <InputLabel className='label'>Usuario</InputLabel>
                                                    <FormControl fullWidth 
                                                    margin="normal">

                                                    <InputLabel id='demo-simple-select-label' >Selecione</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        onChange={onChange} value={value ?? ''}
                                                        color='primary'
                                                        error={!!errors.user_id}
                                                        label='Selecione'
                                                    >
                                                        <MenuItem key={1} value={1??''}>Activo</MenuItem>
                                                        <MenuItem key={0} value={0??''}>Inativo</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} >
                                        <InputLabel className='label'>Ordem</InputLabel>
                                        <Controller
                                            control={control}
                                            name='sort_order'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    margin="normal"
                                                    onChange={onChange}
                                                    value={value ?? ''}
                                                    type={'number'}
                                                    placeholder={'Digite a ordem de apresentação do Location'}
                                                    error={!!errors.sort_order}
                                                    helperText={errors.sort_order ? errors.sort_order.message : ''}
                                                    color='primary' />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} >
                                        <InputLabel className='label'>Descrição</InputLabel>
                                        <Controller
                                            control={control}
                                            name='description'
                                            rules={{
                                                required: 'Campo obrigatorio'
                                            }}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    multiline
                                                    rows={2}
                                                    margin="normal"
                                                    onChange={onChange}
                                                    value={value ?? ''}
                                                    placeholder={'Digite a descrição'}
                                                    error={!!errors.description}
                                                    helperText={errors.description ? errors.description.message : ''}
                                                    color='primary' />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} mb={2} >
                                        {isUpdate ?
                                            <>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    fullWidth
                                                    type='submit'
                                                    disabled={Loading}
                                                    endIcon={Loading ? <CircularProgress size={'1rem'} /> : <Icon>save</Icon> }
                                                    onSubmit={handleSubmit(UpdateData)}
                                                >
                                                    Actualizar
                                                </Button>
                                            </>
                                            : 
                                                <Button
                                                    variant='contained'
                                                    color='success'
                                                    fullWidth
                                                    type='submit'
                                                    disabled={Loading}
                                                    onSubmit={handleSubmit(submitData)}
                                                    endIcon={Loading ? <CircularProgress size={'1rem'} /> : <Icon>save</Icon> }
                                                >
                                                    Guardar
                                                </Button>
                                            }
                                    </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}