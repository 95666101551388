import { api_services } from "./config";

const getAll = async (token) => {
    try {
        const url = `/auth/users`
        
        const result = await api_services(url, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json', 
                Authorization: `Bearer ${token}`
            },
          });          
          return result.json();
    } catch (error) {
        console.log({error});
        throw Error('problemas ao buscar os usuarios')
    }
}

const getOne = async (token, id) => {
    try {
        const url = `/auth/users/${id}`
        
        const result = await api_services(url, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json', 
                Authorization: `Bearer ${token}`
            },
          });          
          return result.json();
    } catch (error) {
        console.log({error});
        throw Error('problemas ao buscar o usuario')
    }
}

export const usersServices = {
    getAll,
    getOne
}