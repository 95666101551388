import styles from './Curso.module.css'
import React, { useMemo } from 'react'
import { MenuDrawer } from '../../components'
import { Environment, Head } from '../../shared'
import {
  Box, Divider, Grid, IconButton, InputBase, LinearProgress, Pagination,
  Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
  Typography, useTheme
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { courseService, GET_COURSES } from '../../shared/services/course';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../shared/hooks';
import MoreCourse from '../../components/Modals/MoreCourse';

export function Course() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [Change, setChange] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [totalAccount, setTotalAccount] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useAuthContext()

  const search = useMemo(() => {
    return searchParams?.get('search') || ''
  }, [searchParams])

  const page = useMemo(() => {
    return searchParams?.get('page') || 1
  }, [searchParams])
  const { palette } = useTheme();

  React.useEffect(() => {
    const findAllCourses = async () => {
      try {
        setLoading(true);
        setError(null);
        const { url, options } = GET_COURSES(page, Environment.ROW_LIMIT, search ?? '', token ?? '');
        const response = await fetch(url, options);
        const result = await response.json();

        setData(result.data);
        setTotalAccount(result.meta.total)
      } catch (error) {
        setError(error.message)
        if (error.message === 'Failed to fetch')
          toast.error('Problemas de conexão com a internet!')
        toast.error(error.message)

      } finally {
        setLoading(false);
      }
    }
    findAllCourses();
    setChange(false)
  }, [search, page, Change]);

  const DeleteCourse = async (id) => {
    setLoading(true);
    setError(null);
    const response = await courseService.DeleteOne(id, token);
    if (response instanceof Error) {
      
      setError(response.message);
      toast.error(response.message)
      setLoading(false);
    } else {
      
      toast.error('eliminado com sucesso!', {
        position: 'top-right',
        className: 'custom-toast',
      })
      setChange(true)
      setLoading(false);
    }
  }

  return (
    <MenuDrawer page='Cursos'>
      <Head title='Cursos' />
      <Box component={Paper} py={2} m={2} flex={1}>
        <Box m={1} ml={2}>
          <section className='bloco-pesquisa-botao'>
            <div className='search'>
              <input type='text' placeholder='Pesquisar'
                onChange={(evt) => { setSearchParams({ search: evt.target.value }, { replace: true }) }}
                value={search ?? ''} />
              <button><i className='fas fa-search'></i></button>
            </div>

            <Link to='/academic/courses/register' className='btn btn-add'>
              Cadastrar Curso <i className='fas fa-plus ml-1'></i>
            </Link>
            <Link to='/academic/courses/erased' className='btn btn-remove'>
              Reciclagem <i className='fas fa-trash ml-1'></i>
            </Link>
          </section>

          <Box marginBottom={5}>
            <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
              <TableContainer component={Paper} elevation={0} sx={{ flexGrow: 1 }} style={{ border: '1px solid #ededed' }}>
                <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                  <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                    <TableRow>
                      <TableCell className='title-table text-center'>Curso</TableCell>
                      <TableCell className='title-table text-center'>Descrição</TableCell>
                      <TableCell className='title-table text-center'>Anos</TableCell>
                      <TableCell className='title-table text-center'>Estado</TableCell>
                      <TableCell className='title-table text-center'>Acções</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0 ?
                      data.map((item) => (
                        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className='text-center cel-name'>{item.name}</TableCell>
                          <TableCell className='text-'>
                            {item.description.length > 120 ? item.description.slice(0, 120) + '...' : item.description}
                          </TableCell>
                          <TableCell className='text-center cel-years'>{item.years}</TableCell>
                          <TableCell className='text-center'>
                            {item.status === 0 ?
                              <p className='curso-inactivo'>Inactivo</p> :
                              <p className='curso-activo'>Activo</p>
                            }
                          </TableCell>
                          <TableCell align="center">
                            <Box display={'flex'} gap={1} justifyContent={'center'}>
                              <Link to={`/academic/courses/info/${item.id}`}>
                                <Typography className='btn-icon btn-edit'>
                                  <i className="fa-solid fa-eye"></i>
                                </Typography>
                              </Link>
                              <Link to={`/academic/courses/update/${item.id}`}>
                                <Typography className='btn-icon btn-edit'>
                                  <i className="fa-solid fa-edit"></i>
                                </Typography>
                              </Link>
                              <Box className='btn-icon btn-edit' onClick={() => DeleteCourse(item.id)}>
                                <i className="fa-solid fa-trash"></i>
                              </Box>
                              <Link className='btn-icon' to={`/people/functionary/${item.id}`}>
                                <i className="fa-solid fa-print"></i>
                              </Link>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )) : (
                        <TableRow>
                          <TableCell colSpan={12} align="center"><Typography width={250}>Lista de Cursos Vazia</Typography></TableCell>
                        </TableRow>
                      )
                    }

                    {loading && (
                      <TableRow>
                        <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                      </TableRow>)
                    }

                  </TableBody>
                  <TableFooter>
                    {error && (
                      <TableRow>
                        <TableCell colSpan={12} align="center"><Typography width={250}>{error}</Typography></TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                        <Pagination
                          count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                          page={Number(page)} onChange={(_, newPage) => { setSearchParams({ _, page: newPage.toString() }, { replace: true }) }}
                          color='primary'
                          size='small'
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </MenuDrawer>
  )
}
