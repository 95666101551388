// styles
import styles from './Login.module.css';
import React from 'react'
import Header from '../../components/Header'
import Input from '../../components/formComponents/Input'
import Button from '../../components/formComponents/Button';
import { Head } from '../../shared';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { loginValidationSchema } from '../../shared/validations/login';

const Login = () => { 
    const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });
    const [existUser, setExistUser] = React.useState(false)
    const navigate = useNavigate()
    const { user, session, isLoading } = useAuthContext()
  
    React.useEffect(() => {
      if (user) navigate('/dash')
    }, [user, existUser])

    const onSubmit = async ({ email, password }) => {
        const result = await session(email, password);
  
        if(result){
          toast.success('Seja Bem vindo!')
          // document.location.href = '/dash'
          setExistUser(true)
        }
    };

    return (
        <>
            <Head title='Login' />
            <Header />
            <section className={styles.login}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <h1 className='title'>Página de Login</h1>
                <p className={styles.paragrafo}>Preencha todos os campos corretamente para fazer o login</p>
                    <Controller
                        name='email'
                        control={control}
                        render={({field: {value, onChange}}) => (
                            <Input 
                                label='Email' 
                                type='email' 
                                name='email' 
                                id='email' 
                                placeholder='Digite o seu email'
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                            />
                        )}
                    />
                    
                    <Controller
                        name='password'
                        control={control}
                        render={({field: {value, onChange}}) => (
                            <Input 
                                label='Senha' 
                                type='password' 
                                name='password' 
                                id='password' 
                                placeholder='Digite a sua senha'
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                            />
                        )}
                    />
                    <Button type={'submit'}>Entrar</Button>
                </form>
            </section>
        </>
    )
}

export default Login
