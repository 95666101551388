import { API } from "./api";
import { api_services } from "./config";

export const GET_CLASSROOMS = (page=1, limit=15, filter='', token = '') => {
    return {
        url: `${API}/classrooms?filter=${filter}&per_page=${limit}&page=${page}`,
        options: {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
    }
}


const POST_REGISTRATION = async (data, token = '') => {

    try {
        const result = await api_services('/StudentRegistrations', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}


const POST_classrooms = async (data, token = '') => {

    try {
        const result = await api_services('/classrooms', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const get_classrooms_exam = async (token = '') => {

    try {
        const result = await api_services('/ExamRoomAccess', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
             },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}


const POST_classrooms_exam = async (data, token = '') => {
    console.log('dados enviados = ', data);
    
    try {
        const result = await api_services('/ExamRoomAccess', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}


const PUT_classrooms_exam = async (data, token = '') => {

    try {
        const result = await api_services('/ExamRoomAccess', {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const updateClassrooms = async (id, data, token = '') => {    
    try {
        const result = await api_services(`/classrooms/${id}`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
             },
            body: JSON.stringify(data),
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getOne = async (id, token = '') => {
    try {
        const result = await api_services(`/classrooms/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            Authorization: `Bearer ${token}`
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getAllDeleted = async (page=1, limit=15, filter='', token = '') => {
    try {
        const result = await api_services(`/classrooms/deleted_at?filter=${filter}&per_page=${limit}&page=${page}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreAllDeleted = async (token) => {
    try {
        const result = await api_services(`/classrooms/restore_all`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const restoreOneDeleted = async (id, token) => {
    try {
        const result = await api_services(`/classrooms/restore_one/${id}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
             },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const DeleteOne = async (id, token) => {    
    try {
        const result = await api_services(`/classrooms/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` },
        })

        return result;

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const classroomService = {
    POST_classrooms,
    getOne,
    DeleteOne,
    updateClassrooms,
    getAllDeleted,
    restoreAllDeleted,
    restoreOneDeleted,
    get_classrooms_exam,
    POST_classrooms_exam,
    PUT_classrooms_exam,
    POST_REGISTRATION
}